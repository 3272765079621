"use client";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { getCookie } from "cookies-next";

const httpLink = createHttpLink({
  uri: "https://dashboard.vulnvision.com/graphql/",
});

// Debug link for logging requests
const debugLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    console.log(`Ending request for ${operation.operationName}`, data);
    return data;
  });
});

// Error handling link
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) {
    // Check for 502 status code
    if ("statusCode" in networkError && networkError.response.status === 502) {
      // Using window.location for immediate redirect
      window.location.href = "/updated";
    }
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(
        `GraphQL Error: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }
});

export const ApolloProviderWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const token =
    useSelector((state: RootState) => state.user.token) || getCookie("token");

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `JWT ${token}` : "",
      },
    };
  });

  const client = new ApolloClient({
    link: ApolloLink.from([
      debugLink,
      errorLink, // Add error handling link
      authLink.concat(httpLink),
    ]),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
